import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про студію | Студія татуювання Demini
			</title>
			<meta name={"description"} content={"Кожен дизайн, кожна лінія, кожен відтінок - це співпраця між нашою командою і вами - носієм мистецтва"} />
			<meta property={"og:title"} content={"Про студію | Студія татуювання Demini"} />
			<meta property={"og:description"} content={"Кожен дизайн, кожна лінія, кожен відтінок - це співпраця між нашою командою і вами - носієм мистецтва"} />
			<meta property={"og:image"} content={"https://demini-black.com/media/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://demini-black.com/media/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://demini-black.com/media/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://demini-black.com/media/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://demini-black.com/media/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://demini-black.com/media/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://demini-black.com/media/images.png"} />
			<meta name={"msapplication-TileColor"} content={"https://demini-black.com/media/images.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box
						background="url(https://demini-black.com/media/about-1.jpg) 50% 15%/cover"
						padding="0px 0px 600px 0px"
						margin="0px 30px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="50%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="#483704"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text
							as="h4"
							margin="0"
							font="--base"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Про нас
						</Text>
						<Text as="h1" margin="0 0 16px 0" font="--headline1" lg-font="--headline2">
							Про Demini
						</Text>
						<Text as="p" margin="16px 0" font="--base" max-width="400px">
							У тату-салоні Demini ми ставимо на перше місце вашу безпеку та комфорт. Кожен інструмент ретельно стерилізується, і ми дотримуємося суворих гігієнічних стандартів. Всі наші майстри регулярно проходять навчання з новітніх протоколів безпеки, щоб гарантувати нашим клієнтам безризиковий досвід.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--headline2"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Ваша історія, наше полотно
						</Text>
						<Text as="p" margin="12px 0" font="--lead">
							Ми віримо, що кожне татуювання має унікальну історію, емоцію або важливий момент, пов'язаний з ним. Ваш досвід, мрії та розповіді - це муза для наших чорнил. У Demini ми робимо більше, ніж просто татуювання, ми вшановуємо ваші історії, перетворюючи їх на вічний витвір мистецтва.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://demini-black.com/media/about-2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://demini-black.com/media/about-3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://demini-black.com/media/about-4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});